import * as _abortcontroller2 from "abortcontroller-polyfill/dist/abortcontroller";

var _abortcontroller = "default" in _abortcontroller2 ? _abortcontroller2.default : _abortcontroller2;

var exports = {};

/**
 * Little AbortController proxy module so we can swap out the implementation easily later.
 */
var _require = _abortcontroller,
    AbortController = _require.AbortController,
    AbortSignal = _require.AbortSignal;

function createAbortError(message) {
  if (message === void 0) {
    message = "Aborted";
  }

  try {
    return new DOMException(message, "AbortError");
  } catch (_unused) {
    // For Internet Explorer
    var error = new Error(message);
    error.name = "AbortError";
    return error;
  }
}

exports.AbortController = AbortController;
exports.AbortSignal = AbortSignal;
exports.createAbortError = createAbortError;
export default exports;
const _AbortController = exports.AbortController,
      _AbortSignal = exports.AbortSignal,
      _createAbortError = exports.createAbortError;
export { _AbortController as AbortController, _AbortSignal as AbortSignal, _createAbortError as createAbortError };